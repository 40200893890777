$(document).ready(function () {
  $(".owl-carousel").owlCarousel({
    items: 1,
    nav: true,
    dots: true,
    loop: true,
    autoplay: !0,
    autoplayTimeout: 2e3,
    autoplayHoverPause: !0,
    navText: ["<div class='arrow-prev'></div>", "<div class='arrow-next'></div>"],
    responsive: {
      0: {
        autoWidth: true,
        autoheight: true
      }
    }
  });

  $(".burger").click(() => {
    $(".header-nav").toggleClass("active");
  });
  $(".nav-item").click(() => {
    $(".header-nav ").toggleClass("active");
  });

  $(".form-body").submit(function (e) {
    e.preventDefault();
    var t = { Name: this[0].value, "E-Mail-Adresse": this[1].value, Nachricht: this[2].value };
    $.ajax({
      type: "POST",
      url: "mail.php",
      cache: !1,
      data: { json: JSON.stringify(t) },
      dataType: "json",
      complete: function () {
        $(".form-body").html(
          `<p class="content-text__paragraph">Vielen Dank für Ihr Interesse. <br> Wir werden uns so schnell wie möglich mit Ihren in Verbindung setzen.</p>`
        );
      },
      success: function (e) {
        $(".form-body").html(
          `<p class="content-text__paragraph">Vielen Dank für Ihr Interesse. <br> Wir werden uns so schnell wie möglich mit Ihren in Verbindung setzen.</p>`
        );
      }
    });
  });

  if (window.innerWidth < 480) {
    $(".begin-bottom__text").html("Sind Sie bereit, <br>Ihr maßgeschneidertes<br>Design zu probieren?");
    $(".concept .title-quote__text").html("“Von der Idee bis zum <br> fertigen Produkt”");
    $(".materials .title-quote__text").html("“Kleine Geschenke <br> erhalten die Kundschaft” ");
    $(".contacts-title").html(
      "Für detailliertere Auskünfte, Angebote <br>  oder weitere Fragen stehe ich Ihnen  <br> gerne zur Verfügung."
    );
    $(".references .title-quote__text").html(
      "“Der Kunde ist – manchmal –<br>König,aber meistens hat<br> er einen anderen Namen”"
    );
  }
});
